export const setFile = (file) => ({
    type: 'SET_FILE',
    payload: file,
});

export const setError = (text) => ({
    type: 'SET_ERROR',
    payload: text,
});

export const setUid = (uid) => ({
    type: 'SET_UID',
    payload: uid,
});

export const setCharacters = (characters) => ({
    type: 'SET_CHARACTERS',
    payload: characters,
});

export const clear = () => ({
    type: 'CLEAR'
});