import { useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useNavigate } from 'react-router-dom';

import { deleteAllMessages } from '../../services/message';

import 'firebaseui/dist/firebaseui.css';
import { getAuth, signInWithEmailAndPassword  } from "firebase/auth";
import './delete.css';

export default function Delete({toastRef}) {

    const auth = getAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    
    const deleteAccount = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                userCredential.user.delete().then(() => {
                    deleteAllMessages(userCredential.user.uid).catch(() => {/*ignore*/});
                    navigate('/');
                }).catch(() => {
                    toastRef.current.show({severity:'error', summary: 'Error', detail:'An error occurred while deleting your account. Please try again later.', life: 5000})
                })
            })
            .catch((error) => {
                const errorCode = error.code;
                if(errorCode === "auth/invalid-email" || errorCode === "auth/invalid-credential"){
                    toastRef.current.show({severity:'error', summary: 'Error', detail:'Invalid email or password.', life: 5000})
                }
            });
    }

    return (
        <div className="flex align-items-center justify-content-center h-full">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                <div className="text-center mb-5">
                    <div className="text-900 text-3xl font-medium mb-3">Delete your account</div>
                    <span className="text-600 font-medium line-height-3">Change your mind?</span>
                    <a className="font-medium no-underline ml-2 cursor-pointer pinkcolortext" onClick={() => navigate('/signin')}>Sign in!</a>
                </div>

                <div>
                    <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                    <InputText id="email" type="text" placeholder="Email address" onChange={(e) => setEmail(e.target.value)} value={email} className="w-full mb-3" />

                    <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
                    <InputText id="password" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} className="w-full mb-3" />

                    <Button label="Delete my account" icon="pi pi-user" className="w-full pingcolorbackground" onClick={() => deleteAccount()}/>
                </div>
            </div>
        </div> 
    )

}