import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, HashRouter, useLocation } from 'react-router-dom';

import MainPage from './renderer/pages/mainPage.js';
import ChatPage from './renderer/pages/chatPage.js';
import Price from './renderer/pages/price.js';
import Profile from './renderer/pages/profile.js';
import LogIn from './renderer/pages/login/login.js';
import Delete from './renderer/pages/delete/delete.js';
import Register from './renderer/pages/register/register.js';
import Reset from './renderer/pages/reset/reset.js';
import Menu from './renderer/components/topMenu/menu.js';

import PrivateRoute from './renderer/components/route/privateRoute.js'

import { getCredits } from './renderer/services/credits.js';
import { Toast } from 'primereact/toast';

import 'primereact/resources/themes/saga-blue/theme.css';  //theme
import 'primereact/resources/primereact.min.css';          //core css
import 'primeicons/primeicons.css';                        //icons
import 'primeflex/primeflex.css';

import './renderer/style/index.css';

//redux
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './renderer/redux/store';
import { setCharacters, setUid } from './renderer/redux/actions.js';

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCWlG6UDF_K-9a17SCcL7mVTi3BsOQxxSE",
  authDomain: "loveai-ceefb.firebaseapp.com",
  projectId: "loveai-ceefb",
  appId: "APP_ID",
};

initializeApp(firebaseConfig);

const ProjectMainComponent = () => {
  const toast = useRef(null);
  const auth = getAuth();
  const location = useLocation();

  const dispatch = useDispatch();

  const uid = useSelector(state => state.uid);

  useEffect(() => {
      // Vérifie l'état de l'authentification à chaque chargement de la page
      const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (!uid && user && user.emailVerified && location.pathname !== "/delete") {
              const uid = user.uid;
              dispatch(setUid(uid));
              getCredits(uid).then((credits) => dispatch(setCharacters(credits)))
              .catch(() => toast.current.show({severity:'error', summary: 'Error', detail:'The server seems to be offline, please try again later.', life: 5000}));
          }
      });

      return () => unsubscribe();
  }, [location]);

  return (
      <div className='h-screen flex flex-column'>
        <Toast ref={toast} />
        <div id="topmenuindex">
          <Menu isAuthenticated={uid !== null}/>
        </div>
        
        <div id="index">
          <Routes>
            <Route exact path="/signin" element={<LogIn toastRef={toast}/>}/>
            <Route exact path="/register" element={<Register toastRef={toast}/>}/>
            <Route exact path="/delete" element={<Delete toastRef={toast}/>}/>
            <Route exact path="/reset" element={<Reset toastRef={toast}/>}/>
            <Route exact path="/" element={<MainPage/>}/>
            <Route exact path="/play/:AIName" element={<PrivateRoute element={ChatPage} isAuthenticated={uid  !== null} toastRef={toast}/>} />
            <Route exact path="/price" element={<PrivateRoute element={Price} isAuthenticated={uid  !== null} />} />
            <Route exact path="/profile" element={<PrivateRoute element={Profile} isAuthenticated={uid  !== null} />} />
          </Routes>
        </div>
      </div>
  );
  
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <HashRouter>
      <ProjectMainComponent />
    </HashRouter>
  </Provider>
);

