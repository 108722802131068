import axios from 'axios';

export async function sendMessage(msg, AIName, uid) {
    const data = {
        "character": AIName,
        "messages": msg,
        "uid": uid
    }
    const response = await axios.post('https://sweetaichat.com/v1/chat/', data);
    return response.data;
}

export async function getMessages(uid, AIName){
    const data = {
        "params": {
            uid: uid,
            AIName: AIName
        }
    }

    const response = await axios.get('https://sweetaichat.com/v1/chat', data);
    return response.data;
}

export async function deleteMessages(uid, AIName){
    const data = {
        "uid": uid,
        "AIName": AIName
    }

    const response = await axios.post('https://sweetaichat.com/v1/chat/delete', data);
    return response.data;
}

export async function deleteAllMessages(uid){
    const data = {
        "uid": uid
    }

    const response = await axios.post('https://sweetaichat.com/v1/chat/deleteAll', data);
    return response.data;
}