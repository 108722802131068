import Message from "./message";
import MessageWaiting from "./messageWaiting";

import './chatMessages.css'
import { useEffect } from "react";

export default function ChatMessages({msg, waitingAnswer}) {

    const chatContainer = document.querySelector('.chat-container');

    useEffect(() => {
        if(chatContainer){
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }, [waitingAnswer, msg])

    return(
        <div className="chat-container">
            {msg.map((message, index) => (
                <Message msg={message} index={index} key={index}/>
            ))}

            {waitingAnswer ? (
                <MessageWaiting index={msg.length}/>
            ) : null}
        </div>
    )

}