import { Image } from 'primereact/image';

import './attributeGrid.css'

import CONSTANTS from '../../../../constants.json'

export default function AttributeGrid({AIName}) {

    let data;
    if(AIName in CONSTANTS.CARACTERS.GIRLS){
        data = CONSTANTS.CARACTERS.GIRLS[AIName].attributes;
    } else {
        data = CONSTANTS.CARACTERS.BOYS[AIName].attributes;
    }

    const itemTemplate = (item) => {
        if(item !== undefined){
            return(
                <div className='itemAttribute'>
                    <Image src={`./images/logo/${item[0]}.png`} className="attributeLogo"/>
                    <p className='itemName'>{item[1]}</p>
                </div>
            )
        }
        return (
            <div className='itemAttribute'></div>
        )
    }

    const lineTemplate = (item1, item2) => {
        return (
          <div className="line1" key={item2}>
            <div>{itemTemplate(item1)}</div>
            <div>{itemTemplate(item2)}</div>
          </div>
        );
      };
    
      return (
        <div className="attribute-container">

            {Object.entries(data).map((attribute, index) => (
                index % 2 === 0 ? (
                    lineTemplate(attribute, Object.entries(data)[index+1])
                ) : null
            ))}

        </div>
      );

}