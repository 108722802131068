import "./message.css"

export default function MessageWaiting({index}) {

    return (
        <div className={index % 2 === 0 ? 'message-left' : 'message-right'}>
            <div className={index % 2 === 0 ? 'box-left' : 'box-right'}>
                <p className="content">...</p>
            </div>
        </div>
    )

}