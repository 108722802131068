import { Button } from 'primereact/button';

import './price.css'

export default function Price() {

    return (
        <div className="pageHome">
            <div className='flex justify-content-center flex-column align-items-center'>
                <p className="pricetext">
                    The website is still in development and, as it's currently in the alpha stage, purchasing credits isn't available yet.
                </p>      
                <div>
                    <Button label="Send a feedback" icon="pi pi-heart" className="pingcolorbackground feedbackButton" onClick={() => window.location.href = `mailto:loveaichat1@gmail.com`}/>  
                </div>
            </div> 
        </div>
    )

}