import { useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useNavigate } from 'react-router-dom';

import 'firebaseui/dist/firebaseui.css';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import './reset.css';

export default function Register({toastRef}) {

    const auth = getAuth();

    const [email, setEmail] = useState("");

    const navigate = useNavigate();
    
    const sendReset = () => {
        sendPasswordResetEmail(auth, email)
        .then(() => {
            toastRef.current.show({severity:'success', summary: 'Success', detail:'A password reset email has been sent. Please check your inbox.', life: 5000})
            navigate('/')
        })
        .catch((error) => {
            toastRef.current.show({severity:'error', summary: 'Error', detail:'There was an error sending the password reset email. Please try again later.', life: 5000})
        });
    }

    return (
        <div className="flex align-items-center justify-content-center h-full">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                <div className="text-center mb-5">
                    <div className="text-900 text-3xl font-medium mb-3">Welcome</div>
                    <span className="text-600 font-medium line-height-3">Already have an account?</span>
                    <a className="font-medium no-underline ml-2 cursor-pointer pinkcolortext"  onClick={() => navigate('/signin')}>Sign In</a>
                </div>

                <div>
                    <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                    <InputText id="email" type="text" placeholder="Email address" onChange={(e) => setEmail(e.target.value)} value={email} className="w-full mb-3" />

                    <Button label="Reset your password" icon="pi pi-user" className="w-full pingcolorbackground" onClick={sendReset}/>
                </div>
            </div>
        </div>
    )

}