import axios from 'axios';

export async function getCredits(uid) {
    const data = {
        "params": {
            uid: uid
        }
    }
    const response = await axios.get('https://sweetaichat.com/v1/credits', data);
    return response.data;
}
