import React, { useState } from 'react';
import Chat from '../components/chat/chat';
import TopChatMenu from '../components/chat/topChatMenu/topChatMenu';

import { useParams } from 'react-router-dom';

import RightMenu from '../components/rightMenu/rightMenu'

import './chatPage.css'

export default function ChatPage({toastRef}) {

    let { AIName } = useParams();
    const [resetChat, setResetChat] = useState(false);

    const handleResetChat = () => {
        setResetChat(true);
        setTimeout(() => setResetChat(false), 0);
    };

    return(
        <div className='h-full chatPage'>
            <div className="mainChatWidth">
                <div className="mainTopChatMenu">
                    <TopChatMenu AIName={AIName} askDeleteMessages={handleResetChat}/>
                </div>
                <div className="mainChat">
                    <Chat AIName={AIName} resetChat={resetChat} toastRef={toastRef}/>
                </div> 
            </div>
            <div className="mainRightMenuWidth">
                <RightMenu AIName={AIName}/>
            </div>
        </div>
    )
} 