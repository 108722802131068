import { Avatar } from 'primereact/avatar';

import './profile.css'

import CONSTANTS from '../../constants.json'

export default function Profile() {
    return (
        <div className="pageProfile">
            <h2 className="titleEnvelope">Your envelopes</h2>
            <div className="profileEnvelopes">
                {Object.entries(CONSTANTS.CARACTERS).map(([key, value]) => (
                    <div className="envelopePrice">
                        <Avatar icon="pi pi-envelope" className="avatarImage" size="xlarge"/>
                        <p className="value">2</p>
                    </div>
                ))}
            </div>
        </div>
    )

}