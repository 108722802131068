import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';

import './mainPage.css'

import introImage from '../../assests/images/introImage5.png'
import CONSTANTS from '../../constants.json'

export default function MainPage() {

    const navigate = useNavigate();

    const onClickCard = (caracters) => {
        navigate(`/play/${caracters}`);
    }

    return (

        <>
            <div className="websiteintro">
                <div className="introDiv">
                    <h1 className="pinkcolortext introtitle">Your sexual AI chatbot</h1>
                    <p className="introtext">With SweetAiChat, you can chat with a wide range of virtual girls or boys who respond just like real people. Create your own world, engage in deep conversations, and explore your wildest scenarios with your new companions.</p>
                </div>
                <img src={introImage} className="introImage"/>
            </div>

            <div className='pb-4'>
                <h2 className="titleCardDisplay">Girls</h2>
                <div className="cardDisplay">
                    {Object.entries(CONSTANTS.CARACTERS.GIRLS).map(([key, value]) => (
                        <Card title={key} style={{backgroundImage: `url(./images/${key}.png)`}} onClick={() => onClickCard(key)} key={key}>
                            <p className="m-0 text-gray-100">
                                {value.desc}
                            </p>
                        </Card>
                    ))}
                </div>

                <h2 className="titleCardDisplay">Boys</h2>
                <div className="cardDisplay">
                    {Object.entries(CONSTANTS.CARACTERS.BOYS).map(([key, value]) => (
                        <Card title={key} style={{backgroundImage: `url(./images/${key}.png)`}} onClick={() => onClickCard(key)} key={key}>
                            <p className="m-0 text-gray-100">
                                {value.desc}
                            </p>
                        </Card>
                    ))}
                </div>
            </div>
        </>
    )

}