import React, { useRef } from 'react';

import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { OverlayPanel } from 'primereact/overlaypanel';

import { useNavigate } from 'react-router-dom';

//Redux
import { useDispatch } from 'react-redux';
import { setUid } from '../../redux/actions';

import { getAuth, signOut } from "firebase/auth";

import loveAiLogo from "./loveAiLogo.png";

import './menu.css'

export default function Menu({isAuthenticated}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const auth = getAuth();

    const op = useRef(null);

    const logout = () => {
        signOut(auth).then(() => {
            dispatch(setUid(null));
            op.current.hide();
        }).catch((error) => {});
    }

    const deleteAccount = () => {
        signOut(auth).then(() => {
            dispatch(setUid(null));
            navigate('/delete');
        }).catch((error) => {});
    }

    const showButton = () => {
        return (!isAuthenticated && window.innerWidth > 650) || isAuthenticated;
    }

    return(
        <>
            <div className="deleteMessage">The website is in alpha testing and will soon be shut down. All your data will be deleted.</div>
            <div className="topmenu">
                <div className="start">
                    <div className="logo">
                        <img src={loveAiLogo} onClick={() => navigate("/")} className='h-full pl-3'/>
                    </div>
                    {showButton() ?
                        <>
                            <Button icon="pi pi-home" label="Home" className="p-button-text menuButton" onClick={() => navigate("/")}/>
                            <Button icon="pi pi-envelope" label="Price" className="p-button-text menuButton" onClick={() => navigate("/price")}/>
                        </>
                        :
                        null
                    }
                </div>
                <div className='flex align-items-center mr-2'>
                    {isAuthenticated ?
                        <>
                            <Avatar icon="pi pi-user" size="small" style={{ backgroundColor: 'var(--global-color-1)', color: '#ffffff' }} shape="circle" onClick={(e) => op.current.toggle(e)} />
                            <OverlayPanel ref={op}>
                                <div className='flex flex-column'>
                                    <Button label="Logout" onClick={logout} className="p-button-outlined p-button-info" />
                                    <Button label="Delete my account" onClick={() => deleteAccount()} id="delete-button" className="p-button-outlined p-button-danger mt-2" />
                                </div>
                            </OverlayPanel>
                        </>
                    : 
                        <>
                            <Button label="Register" className="p-button-outlined pinkcolortext mr-2 topmenubutton" onClick={() => navigate("/register")} />
                            <Button label="Login" className="p-button-outlined pinkcolortext mr-2 topmenubutton" onClick={() => navigate("/signin")}/>
                        </>
                    }
                </div>
            </div>
            </>
    )
}