import { createStore } from 'redux';

// Initial state
const initialState = {
  error: "",
  file: "",
  characters: {},
  uid: null
};

// Reducer function
function tasksReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_FILE':
      return {
        ...state,
        file: action.payload
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload
      };
    case 'SET_UID':
      return{
        ...state,
        uid: action.payload
      };
    case 'SET_CHARACTERS':
      return{
        ...state,
        characters: action.payload
      }
    case 'CLEAR':
      return {
        ...initialState,
        reset: !state.reset,
      };
    default:
      return state;
  }
}

// Create the Redux store
const store = createStore(tasksReducer);

export default store;