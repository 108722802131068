import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import { useNavigate } from 'react-router-dom';

//redux
import { useSelector } from 'react-redux';

import './topChatMenu.css'

export default function TopChatMenu({AIName, askDeleteMessages}) {

    const characters = useSelector(state => state.characters);

    const navigate = useNavigate();

    const accept = () => {
        askDeleteMessages();
    }

    const confirmReset = () => {
        confirmDialog({
            message: `Do you want to delete your conversation with ${AIName}?`,
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept
        });
    };

    return(
        <div className="topChatMenu centerBetween">
            <ConfirmDialog />
            <div className="person">
                <Avatar image={`./images/${AIName}Avatar.png`} size="large" shape="circle" />
                <h2 className="name">{AIName}</h2>
            </div>
            <div className="remainChats">
                <p className="remainChatsText">{characters[AIName]}</p>
                <Button icon="pi pi-envelope" onClick={() => navigate("/price")} className="p-button-rounded p-button-text envelope" aria-label="Submit" />
                <Button icon="pi pi-undo" onClick={confirmReset} className="p-button-rounded p-button-text envelope" aria-label="Submit" />
            </div>
        </div>
    )
}