import "./message.css"

export default function Message({msg, index}) {

    const toBold = (text) => {
        // Utilisation d'une expression régulière pour rechercher du texte entre des astérisques
        return text.replace(/\*(.*?)\*/g, '<b>$1</b>');
    }

    return (
        <div className={index % 2 === 0 ? 'message-left' : 'message-right'}>
            <div className={index % 2 === 0 ? 'box-left' : 'box-right'}>
                <p className="content" dangerouslySetInnerHTML={{ __html: toBold(msg.content) }}/>
            </div>
        </div>
    )

}