import { Image } from 'primereact/image';

import AttributeGrid from './attributeGrid/attributeGrid';

import './rightMenu.css'

export default function RightMenu({AIName}) {

    return(
        <div className="rightMenu">
            <Image src={`./images/${AIName}.png`} alt="Image" width="100%"/>
            <div>
                <AttributeGrid AIName={AIName}/>
            </div>
        </div>
    )

}