import React, {useEffect, useState} from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setCharacters } from '../../redux/actions';

import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext';

import { useNavigate } from 'react-router-dom';

import ChatMessages from "../messages/chatMessages"

import { sendMessage, getMessages, deleteMessages } from '../../services/message';

import CONSTANTS from '../../../constants.json';

import './chat.css'

export default function Chat({AIName, resetChat, toastRef}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [history, setHistory] = useState([]);
    const [msg, setMsg] = useState("");
    const [waitingAnswer, setWaitingAnswer] = useState(false);

    const uid = useSelector(state => state.uid);
    const characters = useSelector(state => state.characters);

    useEffect(() => {
        const greeting = CONSTANTS.CHATS[AIName];
        getMessages(uid, AIName).then((messages) => {
            if(messages.length === 0 && greeting){
                setHistory([{"role": "assistant", "content": greeting}]);
            } else {
                setHistory(messages);
            }
        }).catch((error) => {
            if(history.length === 0 && greeting){
                setHistory([{"role": "assistant", "content": greeting}]);
            }
        });
    }, [])

    useEffect(() => {
        if(resetChat){
            deleteMessages(uid, AIName).then(() => {
                const greeting = CONSTANTS.CHATS[AIName];
                if(greeting){
                    setHistory([{"role": "assistant", "content": greeting}]);
                }
            }).catch((error) => {
                toastRef.current.show({severity:'error', summary: 'Error', detail:'An error occurred while deleting your messages. Please try again later.', life: 5000})
            });
        }
    }, [resetChat]);

    useEffect(() => {
        if(!waitingAnswer && history.length > 0 && history[history.length - 1].role === "user"){
            callApi();
        }
    }, [history])

    const addMessage = (role, msg) => {
        const copieHistory = history.map(item => item);
        copieHistory.push({"role": role, "content": msg})
        if(role === "user"){
            setMsg("");
        } else {
            setWaitingAnswer(false);
        }
        return copieHistory;
    }

    const callApi = async () => {
        setWaitingAnswer(true);
        sendMessage(history, AIName, uid).then((answer) => {
            const newCharacters = { ...characters };
            newCharacters[AIName] = characters[AIName] - 1;
            dispatch(setCharacters(newCharacters));

            setHistory(addMessage(answer.role, answer.content))
        }).catch((error) => {
            if(!error.response || error.response.status !== 402){
                toastRef.current.show({severity:'error', summary: 'Error', detail:'The AI seems to be offline, please try again later.', life: 5000})
            } else {
                navigate("/price");
            }
            const removeUserMessage = history.slice(0, -1);
            setHistory(removeUserMessage);
            setWaitingAnswer(false);
        });
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleButtonClick(); //Same then click on button
        }
    };

    const handleButtonClick = () => {
        if(!waitingAnswer){
            setHistory(addMessage('user', msg));
        }
    }

    return(
        <>
            <ChatMessages msg={history} waitingAnswer={waitingAnswer}/>

            <div className="inputTextContainer">
                <InputText value={msg} onChange={(e) => setMsg(e.target.value)} onKeyPress={handleKeyPress} className='inputTextChat'/>
                <Button onClick={() => handleButtonClick()} icon="pi pi-send" className='sendButton'/>
            </div>
        </>
    )
} 