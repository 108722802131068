import { useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useNavigate } from 'react-router-dom';

import 'firebaseui/dist/firebaseui.css';
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from "firebase/auth";
import './login.css';

export default function LogIn({toastRef}) {

    const auth = getAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    
    const logIn = () => {
        setPersistence(auth, browserLocalPersistence)
        .then(() => {
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;

                    if (!user.emailVerified) {
                        toastRef.current.show({severity:'error', summary: 'Error', detail:'Please confirm your email address.', life: 5000})
                    } else {
                        navigate('/')
                    }
                })
                .catch((error) => {
                    const errorCode = error.code;
                    if(errorCode === "auth/invalid-email" || errorCode === "auth/invalid-credential"){
                        toastRef.current.show({severity:'error', summary: 'Error', detail:'Invalid email or password.', life: 5000})
                    }
                });
        }).catch((error) => {
            console.error("Error setting persistence:", error);
          });
    }

    return (
        <div className="flex align-items-center justify-content-center h-full">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                <div className="text-center mb-5">
                    <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
                    <span className="text-600 font-medium line-height-3">Don't have an account?</span>
                    <a className="font-medium no-underline ml-2 cursor-pointer pinkcolortext" onClick={() => navigate('/register')}>Create today!</a>
                </div>

                <div>
                    <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                    <InputText id="email" type="text" placeholder="Email address" onChange={(e) => setEmail(e.target.value)} value={email} className="w-full mb-3" />

                    <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
                    <InputText id="password" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} className="w-full mb-3" />

                    <div className="flex align-items-center justify-content-end mb-6">
                        <a onClick={() => navigate('/reset')} className="font-medium no-underline ml-2 text-right cursor-pointer pinkcolortext">Forgot your password?</a>
                    </div>

                    <Button label="Sign In" icon="pi pi-user" className="w-full pingcolorbackground" onClick={() => logIn()}/>
                </div>
            </div>
        </div> 
    )

}